// import { useState } from "react";
import MenuItem from "./MenuItem";
function MenuCategory({name, cur_group, cur_subgroup, value} : 
        {name:string, cur_group:string, cur_subgroup:string, value:any}) {
    // console.log('current', cur_group, cur_subgroup); // skkn
    var [mygroup, mysubgroup, category] = name.split(':').map((element:any) => 
        element.trim().toLowerCase());
    
    // console.log(mygroup, mysubgroup, category); // skkn
    var display = 'none';
    // var c_price = '';
    var c_price_block:any = '';

    var c_price = value[0].category_price;

    // if (mygroup=='buffet'){
    //     if (category=='dal') c_price = '6.99'
    //     else if (category=='mains (veg.)') c_price = '8.99'
    //     else if (category=='mains (non-veg.)') c_price = '10.99'
    //     else if (category=='breads') c_price = '1.99'
    //     else if (category=='rice') c_price = '1.99'
    //     c_price_block = <small className="text-primary">{'$' + c_price}</small>
    // }
    if (c_price) c_price_block = <small className="text-black lead">{c_price}</small>
    // TODO: Check that c_price of all value items are same, if not, raise exception.

    if (!cur_group || (cur_group === mygroup && cur_subgroup === mysubgroup)) display = 'block'
        return (
            <div style={{display: display}} className="mb-5 pb-md-1">
                <div className="row mt-4 mt-md-4" id={name.replace(/[\s\(\)\.]/g, '')}>
                    <div className="col-12 col-md-2"></div>
                    <div className="col-12 col-md-8">

                        <div className="row">
                            <div className="col-12 col-md-10" >
                            <h3 className="text-black fs-2 text-uppercase mb-0">{category}</h3>
                            </div>
                            <div className="col-11 col-md-2 text-md-end">{c_price_block}</div>                        
                        </div>
                        {category?<hr/>:null}
                    </div>
                </div>

                <div className="col-md-10 offset-md-1" style={{columnCount:2, columnRule:'1px solid'}}>
                {/* <div className="col-12 col-md-10"> */}
                    {value.map((e:any, i:any) => <MenuItem key={i} menu_item={e} />)}
                {/* </div> */}
                </div>
            </div>
        );
  }
//   text-wrap text-break
export default MenuCategory;
